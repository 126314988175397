/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { pipe, groupBy, reverse } from 'ramda'
import { MDXProvider } from '@mdx-js/react'

import LayoutFullWidth from '../../components/Layout/LayoutFullWidth'
import headerProps from '../../components/propTypes/headerProps'
import {
  Typography,
  Divider,
  AggregatorTemplate,
  TransitionLink,
} from '../../components'
import styles from './ResearchingPageTemplate.module.css'
import { flattenNodes } from '../../utils'
import format from 'date-fns/format'

const groupByYear = pipe(
  groupBy(
    publication =>
      publication.publishedDate &&
      format(new Date(publication.publishedDate), 'yyyy'),
  ),
)

export const ResearchingPageTemplate = ({ data }) => {
  const {
    page,
    publications: { edges: publications },
  } = data
  const { frontmatter } = page
  const { intro } = frontmatter

  const _publications = flattenNodes(publications)
  const publicationsByYear = groupByYear(_publications)

  return (
    <MDXProvider>
      <AggregatorTemplate
        breadcrumbs={[{ label: 'Research' }]}
        intro={intro}
      >
        <Divider thickness={4} />

        {reverse(Object.keys(publicationsByYear)).map(year => {
          {
            /* const publicationsByMonth = groupBy(publication =>
            format(new Date(publication.publishedDate), 'MMM'),
          )(publicationsByYear[year])

          const months = eachMonthOfInterval({
            start: new Date(2019, 12, 1),
            end: new Date(2020, 11, 1),
          })

          const firstMonth = find(month => {
            const formattedDate = format(new Date(month), 'MMM')
            return publicationsByMonth[formattedDate] !== undefined
          })(months) */
          }

          return (
            <div key={year} className={styles.yearGroup}>
              <div className={styles.tabsHeader}>
                <Typography
                  type="boxHeading"
                  tag="strong"
                  className={styles.yearLabel}
                >
                  {year}
                </Typography>
              </div>
              {publicationsByYear[year].map(publication => (
                <TransitionLink
                  key={publication.slug}
                  to={publication.slug}
                  className={styles.publicationTitle}
                >
                  <Typography tag="h5">
                    {publication.title}{' '}
                    <span>
                      {format(
                        new Date(publication.publishedDate),
                        'dd MMMM yyyy',
                      )}
                    </span>
                  </Typography>
                </TransitionLink>
              ))}
              {/* <Tabs initialValue={format(new Date(firstMonth), 'MMM')}>
                <div className={styles.tabsHeader}>
                  <Typography
                    type="boxHeading"
                    tag="strong"
                    className={styles.yearLabel}
                  >
                    {year}
                  </Typography>

                  <TabList className={styles.tabList}>
                    {months.map(month => {
                      const formattedMonth = format(new Date(month), 'MMM')
                      return (
                        publicationsByMonth[formattedMonth] && (
                          <Tab
                            key={formattedMonth}
                            identifier={formattedMonth}
                            kind="link"
                          >
                            {formattedMonth}
                          </Tab>
                        )
                      )
                    })}
                  </TabList>
                </div>

                {months.map(month => {
                  const formattedMonth = format(new Date(month), 'MMM')
                  return (
                    publicationsByMonth[formattedMonth] && (
                      <TabPanel
                        key={formattedMonth}
                        identifier={formattedMonth}
                      >
                        {pipe(
                          sortBy(release =>
                            format(new Date(release.publishedDate), 'dd'),
                          ),
                          reverse,
                        )(publicationsByMonth[formattedMonth]).map(
                          publication => {
                            return (
                              <TransitionLink
                                key={publication.slug}
                                to={publication.slug}
                                className={styles.publicationTitle}
                              >
                                <Typography tag="h5">
                                  {publication.title}{' '}
                                  <span>
                                    {format(
                                      new Date(publication.publishedDate),
                                      'dd MMMM yyyy',
                                    )}
                                  </span>
                                </Typography>
                              </TransitionLink>
                            )
                          },
                        )}
                      </TabPanel>
                    )
                  )
                })}
              </Tabs> */}
            </div>
          )
        })}
      </AggregatorTemplate>
    </MDXProvider>
  )
}

ResearchingPageTemplate.propTypes = {}

const ResearchingPage = ({ data }) => {
  const {
    page: {
      frontmatter: { header },
    },
  } = data
  return (
    <LayoutFullWidth header={header}>
      <ResearchingPageTemplate data={data} />
    </LayoutFullWidth>
  )
}

ResearchingPage.propTypes = {
  page: PropTypes.shape({
    frontmatter: PropTypes.shape({
      header: headerProps,
    }),
  }),
  publications: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        frontmatter: PropTypes.shape({
          title: PropTypes.string,
          excerpt: PropTypes.string,
          publisher: PropTypes.string,
          publishedDate: PropTypes.string,
        }),
      }),
    }),
  ),
}

export default ResearchingPage

export const pageQuery = graphql`
  query ResearchingPageTemplate($slug: String!) {
    page: mdx(fields: { slug: { eq: $slug } }) {
      frontmatter {
        ...headerFields
        ...introFields
      }
    }
    publications: allMdx(
      filter: {
        fields: { contentType: { eq: "publications" } }
        frontmatter: { publishedDate: { ne: "" } }
      }
      sort: { fields: frontmatter___publishedDate, order: DESC }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            publisher
            publishedDate
            excerpt
          }
        }
      }
    }
  }
`
